import type { PropsWithChildren } from 'react'
import { cva } from 'cva'
import { NavigationMenuContext } from './NavigationMenuContext'

interface Vertical {
  vertical?: boolean
}

interface Bordered {
  bordered?: boolean
}

interface Secondary {
  secondary?: boolean
}

interface Pill {
  pill?: boolean
}

export type NavigationMenuProps = Vertical & (Bordered | Secondary | Pill)

function NavigationMenu(props: PropsWithChildren<NavigationMenuProps>) {
  const className = getNavigationMenuClassBuilder()(props)

  return (
    <ul className={className}>
      <NavigationMenuContext.Provider value={props}>
        {props.children}
      </NavigationMenuContext.Provider>
    </ul>
  )
}

export default NavigationMenu

function getNavigationMenuClassBuilder() {
  return cva(['flex w-full flex-wrap items-center dark:text-gray-300'], {
    variants: {
      vertical: {
        true: `flex items-start justify-between space-x-2
        lg:flex-col lg:justify-start lg:space-x-0 lg:space-y-1`,
      },
      bordered: {
        true: `dark:border-dark-800 border-b border-gray-100 pb-1.5 lg:space-x-3`,
      },
    },
  })
}
